/**
 * Constantes de la aplicación
 */
export class Constants {
  /** contexto de la aplicación, debe ser el mismo definido en el web.xml del backend */
  static contextoServicios = 'adminbotsapi/api/';

  /** url para obtener información del usuario */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static SECURITY_USER_INFO = 'seguridad/sesion';

  /** url para obtener información del usuario */
  static securityUserInfo = './assets/server/user.json';

  /** url para enviar la informacion de usuarios */
  static USUARIOS_API = 'cargas/usuarios';

  /** url para enviar la información de privilegios */
  static PRIVILEGIOS_API = 'cargas/privilegios';

  /** url para enviar la información de nombrados */
  static NOMBRADOS_API = 'cargas/nombrados';

  /** url para enviar la información de aplicaciones*/
  static APLICACIONES_API = 'cargas/aplicaciones';

  /** url para cargar la segregacion de conflictos*/
  static SEGREGACION_CONFLICTOS_CARGA = 'cargas/segregacion/conflictos';

  /** url para enviar la información de los roles vs aplicaciones SAP*/
  static ROLES_TRANSACCIONES_SAP_API = 'cargas/roles-transacciones-sap';

  /**url para enviar obtener usuarios cargados */

  static USUARIOS_BUSCAR_API = 'cargas/usuarios';

  /**url para enviar obtener privilegios cargados */

  static PRIVILEGIOS_BUSCAR_API = 'cargas/privilegios';

  /**url para enviar obtener nombrados cargados */

  static NOMBRADOS_BUSCAR_API = 'cargas/nombrados';

  /**url para enviar obtener aplicaciones cargados */

  static APLICACIONES_BUSCAR_API = 'cargas/aplicaciones';

  static PERFILES_BUSCAR_API = 'cargas/perfilesterceros';

  /**url para enviar obtener trazabilidad de cargas */

  // gen_constants

  /**url para obtener y enviar el formulario de responsable sura */
  static FORMULARIO_USUARIO = 'formulario/usuario';
  static FORMULARIO_JEFE = 'formulario/jefe';

  static PARAMETROS = 'parametros';
  static TRANSACCIONES_CRITICAS = 'transaccioncriticas';
  static PROCESOS = 'transaccioncriticas/guardarProceso';
  static LIDERES_CONTROL = 'transaccioncriticas/guardarLiderControl';
  static CICLO_INFORMACION = 'transaccioncriticas/guardarCicloInformacion';
  static COMPANIA = 'transaccioncriticas/guardarCompania';

  static SEGREGACION_FUNCIONES = 'segregacionfunciones';
  static DOCUMENTACION_CONFLICTOS = 'segregacionfunciones/guardarDocumentacionConflicto';
  static CONFLICTO_PROCESO = 'segregacionfunciones/guardarConflictoProceso';

  static USUARIOS_ACTUALIZA_API = 'cargas/actualizausuarios';
  static NOMBRADOS_ACTUALIZA_API = 'cargas/actualizanombrados';

  static CARGA_CONFLICTOS_SAP = 'cargas/conflictossap';

  static ACT_PROCESOS = 'procesos/actualizarProceso';
  static ACT_LIDER_CONTROL = 'procesos/inactivar/liderControl';
  static ACT_CICLO_FINANCIERO = 'procesos/inactivar/cicloFinanciero';
  static ACT_COMPANIA = 'procesos/inactivar/compania';
  static INACTIVAR_TRX = 'procesos/inactivarTransaccion';
  static INACTIVAR_CONFLICTO = 'procesos/inactivarConflicto';

  /**url para obtener responsables sura */
  static OBTENER_RESPONSABLES = 'usuario/obtenerResponsables';
  static ACTUALIZAR_RESPONSABLE = 'usuario/actualizarResponsable';
  static ACTUALIZAR_RESPONSABLE_MASIVO = 'usuario/actualizarResponsableMasivo';

  static INFO_PRIVILEGIOS = "privilegios";

  /*URL para aplicaciones sox del integrador */
  static APLICACIONES_SOX = 'aplicacionesSeus';


  /** url para cargar la información de los conflictos de roles SAP*/
  static CARGA_CONFLICTOS_ROLES_SAP =
    'cargas/conflictossap/carga-conflictos-roles';

  /**Parametros*/
  static FECHA_INICIO_SEGREGACION_JEFES = 'fecha.inicio.segregacion.jefes';
  static FECHA_FIN_SEGREGACION_JEFES = 'fecha.fin.segregacion.jefes';

  static FECHA_INICIO_CONFLICTO_TI = 'fecha.inicio.segregacion.TI';
  static FECHA_FIN_CONFLICTO_TI = 'fecha.fin.segregacion.TI';

  static FECHA_FIN_CAMPANA_JEFES = 'fecha.fin.campana.jefes';

  /** url para cargar la información de los roles compuestos vs roles relacionados SAP*/
  static ROLES_RELACIONADOS_SAP_API = 'cargas/roles-relacionados-sap';
}
